import React from "react";
import { Accordion } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import { sanitize } from "../../utils/content";

const Faqs = () => {

    const faqs = useStaticQuery(graphql`
        query FaqList {
            allSharePointFaqList(sort: {fields: data___fields___Order0}) {
                nodes {
                    data {
                        fields {
                            q: Title
                            a: Answer
                        }
                    }
                }
            }
        }
    `)

    return (
        <div className="faq-content pt-lg-4 pt-6">
            <Accordion
                flush
                defaultActiveKey="0"
                className="accordion pl-1"
            >
            { faqs.allSharePointFaqList.nodes.map((item, i) => 
            
                <Accordion.Item eventKey={i} className="">
                    <Accordion.Button 
                        className="btn-reset border-bottom-0 bg-transparent font-size-5 font-weight-bold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                        style={{borderWidth: "1px", borderColor: '#E6E6E6'}}
                    >{item.data.fields.q}</Accordion.Button>
                    <Accordion.Body>
                        <div className="mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-14 pt-6">
                            <p>{sanitize(item.data.fields.a, false, true)}</p>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            ) }
            </Accordion>
        </div>
    )
}

export default Faqs